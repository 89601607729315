<app-menu></app-menu>

<div>
  <app-banner-image [url]="'assets/img/bid/K2121.jpg'" [darker]="false"></app-banner-image>
</div>

<div class="container-80">
  <h4 class="title-contact">Vegye fel velünk a kapcsolatot!</h4>

  <app-email-home></app-email-home>
</div>

<div class="unique">
  <div class="row row--info">
    <div class="col-12 col-lg-6">
      <p class="unique__title">Kérje egyedi árajánlatunkat!</p>
    </div>
    <div class="col-12 col-lg-6 unique__secound-info">
      <a routerLink="/arajanlat" target="_blank">
        <button class="btn btn-success btn-success--info">Árajánlat kérése</button>
      </a>
    </div>
  </div>
</div>

<app-footer></app-footer>
