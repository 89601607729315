<div id="carouselExampleIndicators" *ngIf="mobile" class="carousel slide" > <!--data-ride="carousel"-->
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100 image-fit" src="assets/img/banner/banner-1.jpg" alt="First slide">
      <div class="carousel-caption d-md-block">
        <div class="carousel-item__title"><span style="font-weight: bold">Ahol a szakértelem, a stílus <br></span> és design találkozik</div>
        <div class="carousel-background"></div>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100 image-fit" src="assets/img/banner/butor2.jpg" alt="First slide">
      <div class="carousel-caption d-md-block">
        <div class="carousel-item__title"><span style="font-weight: bold">Ahol a szakértelem, a stílus <br></span> és design találkozik</div>
        <div class="carousel-background"></div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>


<div id="carouselExampleIndicators1" *ngIf="!mobile" class="carousel slide"> <!-- data-ride="carousel"-->
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators1" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators1" data-slide-to="0"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100 carousel-item__image" src="assets/img/banner/banner-1.jpg" alt="First slide">
      <div class="carousel-caption d-md-block">
        <div class="carousel-item__title"><span style="font-weight: bold">Ahol a szakértelem, a stílus <br></span> és design találkozik</div>
        <div class="carousel-background"></div>
      </div>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100 carousel-item__image" src="assets/img/banner/mb-c1.jpg" alt="First slide">
      <div class="carousel-caption d-md-block">
        <div class="carousel-item__title"><span style="font-weight: bold">Ahol a szakértelem, a stílus <br></span> és design találkozik</div>
        <div class="carousel-background"></div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
