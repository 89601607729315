<app-menu></app-menu>

<div class="galeria-banner"><h1>Galéria</h1></div>

<div class="container-fluid mt-5 margin-size" *ngIf="tablet">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <a class="galery-a" href="galeria/muhely">
        <div class="one-box">
          <img src="assets/img/muhely/boritokep.jpg">
          <div class="one-box__title">
            <span>MŰHELY</span>
          </div>
        </div>
      </a>

    </div>

    <div class="col-12 col-md-4 col-lg-3">
      <a class="galery-a" href="galeria/kiallitas">
      <div class="one-box">
        <img src="assets/img/kiallitas/boritokep.jpg">
        <div class="one-box__title">
          <span>KIÁLLÍTÁS</span>
        </div>
      </div>
      </a>
    </div>

    <div class="col-12 col-md-4 col-lg-3">
      <a  class="galery-a" href="galeria/latvanytervek">
      <div class="one-box">
        <img src="assets/img/latvanytervek/boritokep.jpg">
        <div class="one-box__title">
          <span>LÁTVÁNYTERVEK</span>
        </div>
      </div>
      </a>
    </div>


    <div class="col-12 col-md-4 col-lg-3">
      <a  class="galery-a" href="galeria/elkeszult-munkak">
      <div class="one-box">
        <img src="assets/img/elkeszult-munkak/boritokep.jpg">
        <div class="one-box__title">
          <span>ELKÉSZÜLT MUNKÁK</span>
        </div>
      </div>
      </a>
    </div>

  </div>
</div>

<div class="container-fluid mt-5 margin-size" *ngIf="!tablet">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <a class="galery-a" routerLink="/galeria/muhely">
        <div class="one-box">
          <img src="assets/img/muhely/boritokep.jpg">
          <div class="one-box__title">
            <span>MŰHELY</span>
          </div>
        </div>
      </a>

    </div>

    <div class="col-12 col-md-4 col-lg-3">
      <a class="galery-a" routerLink="/galeria/kiallitas">
        <div class="one-box">
          <img src="assets/img/kiallitas/boritokep.jpg">
          <div class="one-box__title">
            <span>KIÁLLÍTÁS</span>
          </div>
        </div>
      </a>
    </div>

    <div class="col-12 col-md-4 col-lg-3">
      <a  class="galery-a" routerLink="/galeria/latvanytervek">
        <div class="one-box">
          <img src="assets/img/latvanytervek/boritokep.jpg">
          <div class="one-box__title">
            <span>LÁTVÁNYTERVEK</span>
          </div>
        </div>
      </a>
    </div>


    <div class="col-12 col-md-4 col-lg-3">
      <a  class="galery-a" routerLink="/galeria/elkeszult-munkak">
        <div class="one-box">
          <img src="assets/img/elkeszult-munkak/boritokep.jpg">
          <div class="one-box__title">
            <span>ELKÉSZÜLT MUNKÁK</span>
          </div>
        </div>
      </a>
    </div>

  </div>
</div>


<app-footer></app-footer>
