<footer class="site-footer">
  <div class="container-fluid" style="padding: 25px;">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h6>Murányi Bútor Információ</h6>
        <p class="text-justify">
          Amennyiben konyhabútor, gardrób, egyéb méretre készített termékekre van szüksége jó helyen jár! Mindenben támogatni tudjuk, vegye fel velünk a kapcsolatot és kérje szakértői kollégáink segítségét!
        </p>
      </div>

      <div class="col-xs-6 col-md-6">
        <h6>Vedd fel velünk a kapcsolatot!</h6>
        <ul class="footer-links">
          <li>3400 Mezőkövesd, Sándor utca 1-3.</li>
          <li>muranyibutor@gmail.com</li>
          <li>+ 36 20 554 1929</li>
        </ul>
      </div>
    </div>
    <hr>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-sm-6 col-xs-12">
        <p class="copyright-text">Copyright &copy; 2020 All Rights Reserved by Murányi Ottó E.V. / A weboldal a
          <a href="https://weboldalnet.hu/">Weboldalnet.hu</a> készítette.
        </p>
      </div>

      <div class="col-md-4 col-sm-6 col-xs-12">
        <ul class="social-icons">
          <li><a class="facebook" target="_blank" href="https://www.facebook.com/muranyibutor"><i class="fa fa-facebook"></i></a></li>
          <li><a class="twitter"  target="_blank" href="mailto:muranyibutor@gmail.com"><i class="fa fa-envelope-open"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>

