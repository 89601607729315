import { Component, OnInit } from '@angular/core';
import {EmailClass} from "../../email/email-home/email-class";
import {NgForm} from "@angular/forms";
import {EmailBox} from "./email-box";

@Component({
  selector: 'app-bid-home',
  templateUrl: './bid-home.component.html',
  styleUrls: ['./bid-home.component.scss']
})
export class BidHomeComponent implements OnInit {

  constructor() { }

  errorCode;
  emailMail: EmailBox = new EmailBox();

  onSubmit(f: NgForm): void {
    f.invalid;

    if(this.emailMail.first_name === undefined ) {
      this.errorCode='Adja meg vezetéknevét!';
    } else if(this.emailMail.last_name === undefined) {
      this.errorCode='Adja meg a keresztnevét!';
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailMail.email)))  {
      this.errorCode = "Nem jó formátumban adta meg az email címet. Jó formátum: emailcím@domain.hu";
    } else  if(this.emailMail.phone === undefined) {
      this.errorCode='Adja meg a telefonszámát!';
    } else if(this.emailMail.phone.length < 10 ) {
      this.errorCode='Létező telefonszámot adjon meg!';
    } else if(this.emailMail.package === undefined) {
      this.errorCode = 'Nem küldheti el az üzenetet üzenet nélkül!';
      f.valid;
    } else {
      this.errorCode = '';
      Email.send({
        Host: 'eskimo.dima.hu',
        Username: 'admin@nyitrai.smtpcsomag.hu',
        Password: 'E8s5R8g1',
        To: 'muranyibutor@gmail.com',
        From: 'info@muranyibutor.hu',
        Subject: `${this.emailMail.last_name}`+' küldött neked egy üzenetet a muranyibutor.hu oldalról!',
        Body: `
<i>Kaptál egy ajánlat kérést a muranyibutor.hu oldalról ${this.emailMail.last_name}-tól!</i> <br/> <b>Név: ${this.emailMail.first_name} ${this.emailMail.last_name}</b><br /> <b>Email címe: ${this.emailMail.email}</b><br /> <b>Telefonszáma: ${this.emailMail.phone}</b><br /> <b>Az alábbi termékkel kapcsolatban írt önnek: ${this.emailMail.package}</b> <br /> <br><br> <b>~Vége az üzenetnek.~</b> `
      });

      f.resetForm();
      alert("Üzenetét megkaptuk, hamarosan válaszolunk!");
    }
  }


  ngOnInit(): void {
  }
}

var Email = {
  send: function (a) {
    return new Promise(function (n, e) {
      a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send";
      var t = JSON.stringify(a);
      Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
        n(e)
      })
    })
  }, ajaxPost: function (e, n, t) {
    var a = Email.createCORSRequest("POST", e);
    a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () {
      var e = a.responseText;
      null != t && t(e)
    }, a.send(n)
  }, ajax: function (e, n) {
    var t = Email.createCORSRequest("GET", e);
    t.onload = function () {
      var e = t.responseText;
      null != n && n(e)
    }, t.send()
  }, createCORSRequest: function (e, n) {
    var t = new XMLHttpRequest;
    let XDomainRequest;
    return "withCredentials" in t ? t.open(e, n, !0) : "undefined" != typeof XDomainRequest ? (t = new XDomainRequest).open(e, n) : t = null, t
  }
};
