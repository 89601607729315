import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./page/home/home.component";
import {ServiceHomeComponent} from "./service/service-home/service-home.component";
import {GaleryHomeComponent} from "./galery/galery-home/galery-home.component";
import {ContactHomeComponent} from "./contact/contact-home/contact-home.component";
import {BidHomeComponent} from "./bid/bid-home/bid-home.component";
import {FurniturePanelPageComponent} from "./furniture-panel-page/furniture-panel-page.component";
import {GaleryMainComponent} from "./page/galery-main/galery-main.component";
import {ElkeszultMunkakComponent} from "./galery/elkeszult-munkak/elkeszult-munkak.component";
import {LatvanytervekComponent} from "./galery/latvanytervek/latvanytervek.component";
import {KiallitasComponent} from "./galery/kiallitas/kiallitas.component";
import {MuhelyComponent} from "./galery/muhely/muhely.component";


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'szolgaltatasok', component: ServiceHomeComponent},
  {path: 'galeria', component: GaleryMainComponent},
  {path: 'kapcsolat', component: ContactHomeComponent},
  {path: 'arajanlat', component: BidHomeComponent},
  {path: 'butorlapjaink', component: FurniturePanelPageComponent},
  {path: 'galeria/muhely', component: MuhelyComponent},
  {path: 'galeria/kiallitas', component: KiallitasComponent},
  {path: 'galeria/latvanytervek', component: LatvanytervekComponent},
  {path: 'galeria/elkeszult-munkak', component: ElkeszultMunkakComponent},
  {path: '**', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
