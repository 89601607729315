import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery-9";

@Component({
  selector: 'app-muhely',
  templateUrl: './muhely.component.html',
  styleUrls: ['./muhely.component.scss']
})
export class MuhelyComponent implements OnInit {


  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '1400px',
        height: '800px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
      // max-width
      {
        breakpoint: 1600,
        width: '1200px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 1200,
        width: '900px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 991,
        width: '530px',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 530,
        width: '300px',
        height: '300px',
        preview: false,
        thumbnailsColumns: 4,
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/galery/muhely/muhely1.jpg',
        medium: 'assets/galery/muhely/muhely1.jpg',
        big: 'assets/galery/muhely/muhely1.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely2.jpg',
        medium: 'assets/galery/muhely/muhely2.jpg',
        big: 'assets/galery/muhely/muhely2.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely3.jpg',
        medium: 'assets/galery/muhely/muhely3.jpg',
        big: 'assets/galery/muhely/muhely3.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely4.jpg',
        medium: 'assets/galery/muhely/muhely4.jpg',
        big: 'assets/galery/muhely/muhely4.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely5.jpg',
        medium: 'assets/galery/muhely/muhely5.jpg',
        big: 'assets/galery/muhely/muhely5.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely6.jpg',
        medium: 'assets/galery/muhely/muhely6.jpg',
        big: 'assets/galery/muhely/muhely6.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely7.jpg',
        medium: 'assets/galery/muhely/muhely7.jpg',
        big: 'assets/galery/muhely/muhely7.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely8.jpg',
        medium: 'assets/galery/muhely/muhely8.jpg',
        big: 'assets/galery/muhely/muhely8.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely9.jpg',
        medium: 'assets/galery/muhely/muhely9.jpg',
        big: 'assets/galery/muhely/muhely9.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely10.jpg',
        medium: 'assets/galery/muhely/muhely10.jpg',
        big: 'assets/galery/muhely/muhely10.jpg',
      },
      {
        small: 'assets/galery/muhely/muhely11.jpg',
        medium: 'assets/galery/muhely/muhely11.jpg',
        big: 'assets/galery/muhely/muhely11.jpg',
      },
    ];

  }
}
