<app-menu></app-menu>

<div class="galeria-banner"><h1>Bútorlapjaink</h1></div>

<div class="container-fluid" style="position: relative">
  <div style="width: 60%;">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="future-box"></ngx-gallery>
  </div>

</div>


<div class="footer-content">
  <app-footer></app-footer>
</div>
