<app-menu></app-menu>

<app-banner-image [url]="'assets/img/banner/B4.jpg'" [darker]="false"></app-banner-image>
<div class="container-80">
  <div class="container-fluid container-fluid--muranyi-service">
    <div class="muranyi-service__container">
      <div class="row">
        <div class="col-12">
          <h1>Egyedi<br>konyhabútorok<br>készítése</h1>
          <div class="line line--furniture"></div>
        </div>
      </div>
      <div class="row">
        <div class="muranyi-service__image col-12 col-xl-8 no-padding">
          <img src="assets/img/service/A-A.jpg">
        </div>
        <div class="muranyi-service__second-image col-12 col-xl-4 no-padding">
          <a class="routerLink" routerLink="/galeria">
            <img src="assets/img/service/4501KEP4.jpg">
            <span class="muranyi-service__galery">Galéria megtekintése  <i class="fa fa-long-arrow-right"></i></span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid container-fluid--muranyi-service">
    <div class="muranyi-service__container">
      <div class="row">
        <div class="col-12">
          <h1>Gardróbok<br>személyre<br>szabva</h1>
          <div class="line line--furniture"></div>
        </div>
      </div>
      <div class="row">
        <div class="muranyi-service__image col-12 col-xl-8 no-padding">
          <img src="assets/img/service/A431-D122.jpg">
        </div>

        <div class="muranyi-service__second-image col-12 col-xl-4 no-padding">
          <a class="routerLink" routerLink="/galeria">
            <img src="assets/img/service/FE200130001.jpg">
            <span class="muranyi-service__galery">Galéria megtekintése  <i class="fa fa-long-arrow-right"></i></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
