<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active darker-items" *ngIf="darker">
      <img class="d-block w-100 carousel-item__image" src="{{bannerUrl}}" alt="Szolgáltatások kép">
    </div>
    <div class="carousel-item active" *ngIf="!darker">
      <img class="d-block w-100 carousel-item__image" src="{{bannerUrl}}" alt="Szolgáltatások kép">
    </div>
  </div>
</div>
