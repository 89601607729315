import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery-9";

@Component({
  selector: 'app-furniture-panel-page',
  templateUrl: './furniture-panel-page.component.html',
  styleUrls: ['./furniture-panel-page.component.scss']
})
export class FurniturePanelPageComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '1600px',
        height: '800px',
        thumbnailsColumns: 3,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
      // max-width
      {
        breakpoint: 1600,
        width: '1200px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 3,
        preview: false
      },
      {
        breakpoint: 1200,
        width: '900px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 3,
        preview: false
      },
      {
        breakpoint: 991,
        width: '530px',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 3,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 530,
        width: '300px',
        height: '300px',
        preview: false,
        thumbnailsColumns: 0,
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/furniture/By 124 SWO Bavaria bükk_v.jpg',
        medium: 'assets/furniture/By 124 SWO Bavaria bükk_v.jpg',
        big: 'assets/furniture/By 124 SWO Bavaria bükk_v.jpg'
      },
      {
        small: 'assets/furniture/By100 SWN Bodega_v.jpg',
        medium: 'assets/furniture/By100 SWN Bodega_v.jpg',
        big: 'assets/furniture/By100 SWN Bodega_v.jpg'
      },
      {
        small: 'assets/furniture/By150  SWO Calvados_v.jpg',
        medium: 'assets/furniture/By150  SWO Calvados_v.jpg',
        big: 'assets/furniture/By150  SWO Calvados_v.jpg'
      },
      {
        small: 'assets/furniture/By 175 SWO Wenge_v.jpg',
        medium: 'assets/furniture/By 175 SWO Wenge_v.jpg',
        big: 'assets/furniture/By 175 SWO Wenge_v.jpg'
      },
      {
        small: 'assets/furniture/By 202 SWO Doni dió_v.jpg',
        medium: 'assets/furniture/By 202 SWO Doni dió_v.jpg',
        big: 'assets/furniture/By 202 SWO Doni dió_v.jpg'
      },
      {
        small: 'assets/furniture/By 204 SWO Lyoni dió_v.jpg',
        medium: 'assets/furniture/By 204 SWO Lyoni dió_v.jpg',
        big: 'assets/furniture/By 204 SWO Lyoni dió_v.jpg'
      },
      {
        small: 'assets/furniture/By 322 SWN Santana tölgy_v.jpg',
        medium: 'assets/furniture/By 322 SWN Santana tölgy_v.jpg',
        big: 'assets/furniture/By 322 SWN Santana tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 325 SWN Sonoma_v.jpg',
        medium: 'assets/furniture/By 325 SWN Sonoma_v.jpg',
        big: 'assets/furniture/By 325 SWN Sonoma_v.jpg'
      },
      {
        small: 'assets/furniture/By 348 SWN Boniface tölgy_v.jpg',
        medium: 'assets/furniture/By 348 SWN Boniface tölgy_v.jpg',
        big: 'assets/furniture/By 348 SWN Boniface tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 350 SWN Bordeaux tölgy_v.jpg',
        medium: 'assets/furniture/By 350 SWN Bordeaux tölgy_v.jpg',
        big: 'assets/furniture/By 350 SWN Bordeaux tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 351 SWN sötét Sonoma tölgy_v.jpg',
        medium: 'assets/furniture/By 351 SWN sötét Sonoma tölgy_v.jpg',
        big: 'assets/furniture/By 351 SWN sötét Sonoma tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 355 SWN Madura tölgy_v.jpg',
        medium: 'assets/furniture/By 355 SWN Madura tölgy_v.jpg',
        big: 'assets/furniture/By 355 SWN Madura tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 358 SWN Yukon tölgy_v.jpg',
        medium: 'assets/furniture/By 358 SWN Yukon tölgy_v.jpg',
        big: 'assets/furniture/By 358 SWN Yukon tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 365 SWN Canyon tölgy_v.jpg',
        medium: 'assets/furniture/By 365 SWN Canyon tölgy_v.jpg',
        big: 'assets/furniture/By 365 SWN Canyon tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 370 SWN Wellington tölgy_v.jpg',
        medium: 'assets/furniture/By 370 SWN Wellington tölgy_v.jpg',
        big: 'assets/furniture/By 370 SWN Wellington tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 373 SWN Ancona tölgy_v.jpg',
        medium: 'assets/furniture/By 373 SWN Ancona tölgy_v.jpg',
        big: 'assets/furniture/By 373 SWN Ancona tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 374 SWN Stirling tölgy_v.jpg',
        medium: 'assets/furniture/By 374 SWN Stirling tölgy_v.jpg',
        big: 'assets/furniture/By 374 SWN Stirling tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 375 WML Monastery tölgy_v.jpg',
        medium: 'assets/furniture/By 375 WML Monastery tölgy_v.jpg',
        big: 'assets/furniture/By 375 WML Monastery tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 376 WML Wotan tölgy_v.jpg',
        medium: 'assets/furniture/By 376 WML Wotan tölgy_v.jpg',
        big: 'assets/furniture/By 376 WML Wotan tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 384 SWN Monterey tölgy_v.jpg',
        medium: 'assets/furniture/By 384 SWN Monterey tölgy_v.jpg',
        big: 'assets/furniture/By 384 SWN Monterey tölgy_v.jpg'
      },
      {
        small: 'assets/furniture/By 435 Oxford cseresznye_v.jpg',
        medium: 'assets/furniture/By 435 Oxford cseresznye_v.jpg',
        big: 'assets/furniture/By 435 Oxford cseresznye_v.jpg'
      },
      {
        small: 'assets/furniture/By 464 SWN Coimbra kőris_v.jpg',
        medium: 'assets/furniture/By 464 SWN Coimbra kőris_v.jpg',
        big: 'assets/furniture/By 464 SWN Coimbra kőris_v.jpg'
      },
      {
        small: 'assets/furniture/By 482 SWO Juhar_v.jpg',
        medium: 'assets/furniture/By 482 SWO Juhar_v.jpg',
        big: 'assets/furniture/By 482 SWO Juhar_v.jpg'
      },
      {
        small: 'assets/furniture/By 501 SWO Szilva_v.jpg',
        medium: 'assets/furniture/By 501 SWO Szilva_v.jpg',
        big: 'assets/furniture/By 501 SWO Szilva_v.jpg'
      },
      {
        small: 'assets/furniture/By 523 SWN Natúr fenyő_v.jpg',
        medium: 'assets/furniture/By 523 SWN Natúr fenyő_v.jpg',
        big: 'assets/furniture/By 523 SWN Natúr fenyő_v.jpg'
      },{
        small: 'assets/furniture/By 535 WML Jackson fenyő_v.jpg',
        medium: 'assets/furniture/By 535 WML Jackson fenyő_v.jpg',
        big: 'assets/furniture/By 535 WML Jackson fenyő_v.jpg'
      },{
        small: 'assets/furniture/By 601,12 SM Korpuszfehér_v.jpg',
        medium: 'assets/furniture/By 601,12 SM Korpuszfehér_v.jpg',
        big: 'assets/furniture/By 601,12 SM Korpuszfehér_v.jpg'
      },{
        small: 'assets/furniture/By 601,16 SM Korpuszfehér_v.jpg',
        medium: 'assets/furniture/By 601,16 SM Korpuszfehér_v.jpg',
        big: 'assets/furniture/By 601,16 SM Korpuszfehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 601,18 SM Korpuszfehér_v.jpg',
        medium: 'assets/furniture/By 601,18 SM Korpuszfehér_v.jpg',
        big: 'assets/furniture/By 601,18 SM Korpuszfehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 601 SM Korpuszfehér_v.jpg',
        medium: 'assets/furniture/By 601 SM Korpuszfehér_v.jpg',
        big: 'assets/furniture/By 601 SM Korpuszfehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 601 ,10 Korpuszfehér_v.jpg',
        medium: 'assets/furniture/By 601 ,10 Korpuszfehér_v.jpg',
        big: 'assets/furniture/By 601 ,10 Korpuszfehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 605 PE Frontfehér prégelt_v.jpg',
        medium: 'assets/furniture/By 605 PE Frontfehér prégelt_v.jpg',
        big: 'assets/furniture/By 605 PE Frontfehér prégelt_v.jpg'
      },
      {
        small: 'assets/furniture/By 605 SM Front fehér_v.jpg',
        medium: 'assets/furniture/By 605 SM Front fehér_v.jpg',
        big: 'assets/furniture/By 605 SM Front fehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 605 SM Frontfehér_v.jpg',
        medium: 'assets/furniture/By 605 SM Frontfehér_v.jpg',
        big: 'assets/furniture/By 605 SM Frontfehér_v.jpg'
      },
      {
        small: 'assets/furniture/By 605 SWN Frontfehér mélyerezetes_v.jpg',
        medium: 'assets/furniture/By 605 SWN Frontfehér mélyerezetes_v.jpg',
        big: 'assets/furniture/By 605 SWN Frontfehér mélyerezetes_v.jpg'
      },
      {
        small: 'assets/furniture/By 605 TM Frontfehér matt ÚJ_v.jpg',
        medium: 'assets/furniture/By 605 TM Frontfehér matt ÚJ_v.jpg',
        big: 'assets/furniture/By 605 TM Frontfehér matt ÚJ_v.jpg'
      },
      {
        small: 'assets/furniture/By 621 PE Hamuszürke_v.jpg',
        medium: 'assets/furniture/By 621 PE Hamuszürke_v.jpg',
        big: 'assets/furniture/By 621 PE Hamuszürke_v.jpg'
      },
      {
        small: 'assets/furniture/By 625 PE Bézs_v.jpg',
        medium: 'assets/furniture/By 625 PE Bézs_v.jpg',
        big: 'assets/furniture/By 625 PE Bézs_v.jpg'
      },
      {
        small: 'assets/furniture/By 632 PE Vanilia  KIFUTÓ_v.jpg',
        medium: 'assets/furniture/By 632 PE Vanilia  KIFUTÓ_v.jpg',
        big: 'assets/furniture/By 632 PE Vanilia  KIFUTÓ_v.jpg'
      },
      {
        small: 'assets/furniture/By 660 PE Prégelt fekete_v.jpg',
        medium: 'assets/furniture/By 660 PE Prégelt fekete_v.jpg',
        big: 'assets/furniture/By 660 PE Prégelt fekete_v.jpg'
      },
      {
        small: 'assets/furniture/By 662 PO Sötét barna_v.jpg',
        medium: 'assets/furniture/By 662 PO Sötét barna_v.jpg',
        big: 'assets/furniture/By 662 PO Sötét barna_v.jpg'
      },
      {
        small: 'assets/furniture/By 665 PO Grafitszürke_v.jpg',
        medium: 'assets/furniture/By 665 PO Grafitszürke_v.jpg',
        big: 'assets/furniture/By 665 PO Grafitszürke_v.jpg'
      },
      {
        small: 'assets/furniture/By 806 PO Capuccino_v.jpg',
        medium: 'assets/furniture/By 806 PO Capuccino_v.jpg',
        big: 'assets/furniture/By 806 PO Capuccino_v.jpg'
      },
      {
        small: 'assets/furniture/By 816 TM Beton_v.jpg',
        medium: 'assets/furniture/By 816 TM Beton_v.jpg',
        big: 'assets/furniture/By 816 TM Beton_v.jpg'
      },
      {
        small: 'assets/furniture/By 817 TM Onyx_v.jpg',
        medium: 'assets/furniture/By 817 TM Onyx_v.jpg',
        big: 'assets/furniture/By 817 TM Onyx_v.jpg'
      },
    ];

  }
}
