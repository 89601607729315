import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {


  mobile;

  constructor() { }

  ngOnInit(): void {
    if (window.screen.width < 991) { // 768px portrait
      this.mobile = true;
    }
  }

}
