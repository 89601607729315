<app-menu></app-menu>

<app-carousel></app-carousel>

<div class="container-fluid icon-display">
  <div class="row">
    <div class="icon-display__block col-12 col-lg-4">
      <div class="icon-display__image">
        <img src="assets/img/icon/conversation.svg">
      </div>
      <h4 class="icon-display__description">Megrendelőinknek a tervezés során iránymutatást adunk a legújabb trendekről,
        színekről és stílusokról.</h4>
    </div>
    <div class="icon-display__block col-12 col-lg-4">
      <div class="icon-display__image">
        <img src="assets/img/icon/monitor.svg">
      </div>
      <h4 class="icon-display__description">Designer és tervező szakembereink készítik el a konyhabútorok
        professzionális terveit és 3D látványterveket. Megrendelés esetén a látványtervet AJÁNDÉKBA adjuk!</h4>
    </div>

    <div class="icon-display__block col-12 col-lg-4">
      <div class="icon-display__image">
        <img src="assets/img/icon/help.svg">
      </div>
      <h4 class="icon-display__description">Ha Ön is konyhabútor készítés előtt áll, már tudja, milyen nagy kihívás az
        adott alapterületből a legjobbat kihozni. Bízza ránk, segítünk!</h4>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-12 laptop-screen-hidden-lg">
      <div class="kitchen-image">
        <img class="image-fit" src="assets/img/banner/kitchen-banner.png">
      </div>
    </div>
    <div class="col-12">
      <div class="otto-story">
        <div class="otto-story__description">

          <div class="row">
            <div class="col-12 col-lg-8 otto-story__design">
              <h3 class="otto-story__h3">Mit nyújtunk ön számára?</h3>
              <div class="row">
                <div class="col-12 col-lg-6">

                  <p>Cégünk konyhabútor, gardrób és egyéb faipari termékekkel foglalkozik. Számunkra nagyon fontos az
                    ügyfeleink elégedettsége és termékeink prémium minősége.
                  </p>
                  <p>Murányi Bútor története több tíz évre nyúlik vissza ezalatt az évek alatt gépesítettük
                    munkafolyamatainkat a kiváló eredmény érdekében.</p>

                  <p>Általunk készített bútorok teljesen egyedeiek az ön fantáziájának terméke. Igényfelmérést követően megkezdődik a tervezés, gyártás végül a beszerelés </p>

                </div>

                <div class="col-12 col-lg-6">
                  <p>
                    Mezőkövesdi irodánk Mezőkövesd, Sándor utca 1-3. szám alatt található, ahova várjuk önöket! Szakmai
                    tanácsadás, 3D látványtervezés, bútorlap színének kiválasztássával kapcsolatban.
                  </p>

                  <p>
                    Amennyiben egyedi konyhabútoron gondolkodik fáradjon be hozzánk, mi pedig elkészítjük, beszereljük és
                    átadjuk az Ön számára álmai konyhabútorát.
                  </p>
                  <p>
                    Nálunk nincsennek akadályok, mindig az ügyfél az első! Várjuk sok szeretettel!
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 laptop-1-screen-hidden-lg">
              <div class="kitchen-image">
                <img class="image-fit" src="assets/img/banner/kitchen-banner.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flow-container">
  <app-machine></app-machine>

  <app-workflow></app-workflow>

  <app-furniture-panel></app-furniture-panel>
</div>

<app-footer></app-footer>
