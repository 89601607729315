<div class="container-fluid" style="margin-bottom: 60px;">
  <h3 class="workflow-main__h3">Munkafolyamat</h3>

  <div class="row">
    <div class="col-12 col-lg-7 col-xl-8 workflow-main__display">
      <div class="row">
        <div class="col-12">
          <div class="workflow-main-container">
            <div class=" workflow-main__box--one">
              <img src="assets/img/workflow/butorkep3.jpg">
            </div>

            <div class=" workflow-main__box--two">
              <img src="assets/img/workflow/butorkep2.jpg">
            </div>
          </div>

          <div class="col-12 col-no-padding">
            <div class=" workflow-main__box--three">
              <img src="assets/img/workflow/butorkep1.jpg">
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 col-lg-5 col-xl-4 workflow-main__display workflow-main__display--two">
      <img src="assets/img/workflow/infografika.svg">
    </div>
  </div>


</div>
