import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery-9";

@Component({
  selector: 'app-latvanytervek',
  templateUrl: './latvanytervek.component.html',
  styleUrls: ['./latvanytervek.component.scss']
})
export class LatvanytervekComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '1400px',
        height: '800px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
      // max-width
      {
        breakpoint: 1600,
        width: '1200px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 1200,
        width: '900px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 991,
        width: '530px',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 530,
        width: '300px',
        height: '300px',
        preview: false,
        thumbnailsColumns: 4,
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/img/galery/butor1.jpg',
        medium: 'assets/img/galery/butor1.jpg',
        big: 'assets/img/galery/butor1.jpg'
      },
      {
        small: 'assets/img/galery/butor2.jpg',
        medium: 'assets/img/galery/butor2.jpg',
        big: 'assets/img/galery/butor2.jpg'
      },
      {
        small: 'assets/img/galery/butor3.jpg',
        medium: 'assets/img/galery/butor3.jpg',
        big: 'assets/img/galery/butor3.jpg'
      },
      {
        small: 'assets/img/galery/butor4.jpg',
        medium: 'assets/img/galery/butor4.jpg',
        big: 'assets/img/galery/butor4.jpg'
      },
      {
        small: 'assets/img/galery/butor5.jpg',
        medium: 'assets/img/galery/butor5.jpg',
        big: 'assets/img/galery/butor5.jpg'
      },
      {
        small: 'assets/img/galery/butor6.jpg',
        medium: 'assets/img/galery/butor6.jpg',
        big: 'assets/img/galery/butor6.jpg'
      },
      {
        small: 'assets/img/galery/butor7.jpg',
        medium: 'assets/img/galery/butor7.jpg',
        big: 'assets/img/galery/butor7.jpg'
      },
      {
        small: 'assets/img/galery/butor8.jpg',
        medium: 'assets/img/galery/butor8.jpg',
        big: 'assets/img/galery/butor8.jpg'
      },
      {
        small: 'assets/img/galery/butor9.jpg',
        medium: 'assets/img/galery/butor9.jpg',
        big: 'assets/img/galery/butor9.jpg'
      },
      {
        small: 'assets/img/galery/butor10.jpg',
        medium: 'assets/img/galery/butor10.jpg',
        big: 'assets/img/galery/butor10.jpg'
      },
      {
        small: 'assets/img/galery/butor12.jpg',
        medium: 'assets/img/galery/butor12.jpg',
        big: 'assets/img/galery/butor12.jpg'
      },
      {
        small: 'assets/img/galery/butor13.jpg',
        medium: 'assets/img/galery/butor13.jpg',
        big: 'assets/img/galery/butor13.jpg'
      },
      {
        small: 'assets/img/galery/butor14.jpg',
        medium: 'assets/img/galery/butor14.jpg',
        big: 'assets/img/galery/butor14.jpg'
      },
      {
        small: 'assets/img/galery/butor15.jpg',
        medium: 'assets/img/galery/butor15.jpg',
        big: 'assets/img/galery/butor15.jpg'
      },
      {
        small: 'assets/img/galery/butor16.jpg',
        medium: 'assets/img/galery/butor16.jpg',
        big: 'assets/img/galery/butor16.jpg'
      },
    ];

  }

}
