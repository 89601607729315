import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-galery-main',
  templateUrl: './galery-main.component.html',
  styleUrls: ['./galery-main.component.scss']
})
export class GaleryMainComponent implements OnInit {

  constructor() { }

  tablet;

  ngOnInit(): void {
    if (window.screen.width < 767) { // 768px portrait
      this.tablet = true;
    }
  }

}
