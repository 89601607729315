<app-menu></app-menu>

<div class="container-fluid" style="position: relative">
  <h4 class="galery-tick">Eddigi munkáink</h4>

  <ngx-gallery [options]="galleryOptions" [images]="galleryImages" style="position: absolute;
    left: 50%;
    transform: translateX(-50%);"></ngx-gallery>
</div>


<div class="footer-content">
  <app-footer></app-footer>
</div>
