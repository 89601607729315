import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  tablet;
  newclass;

  constructor() { }

  newclick(): string {
      return 'collapsed';
  }

  ngOnInit(): void {
    if (window.screen.width < 767) { // 768px portrait
      this.tablet = true;
    }
  }

}
