<div class="container-fluid">
  <form id="contact" (ngSubmit)="onSubmit(contactForm);" #contactForm="ngForm">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <input [(ngModel)]="emailMail.first_name" name="first_name" id="first-name-for" class="form-control"
                 placeholder="Vezetéknév">
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="form-group">
          <input [(ngModel)]="emailMail.last_name" name="last_name" id="last-name-for" class="form-control"
                 placeholder="Keresztnév">
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="form-group">
          <input [(ngModel)]="emailMail.email" name="email" id="email-for" class="form-control"
                 placeholder="E-mail cím">
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="form-group">
          <input [(ngModel)]="emailMail.phone" name="phone" id="phone-for" class="form-control"
                 placeholder="Telefonszám">
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="form-group">
          <input [(ngModel)]="emailMail.message" name="message" id="message-for" class="form-control form-control--message"
                 placeholder="Üzenet">
        </div>
      </div>

      <div class="col-12">
        <p style="color: red;">{{ errorCode }}</p>
        <button type="submit" class="btn btn-primary btn-send-email" [disabled]="contactForm.invalid">Küldés</button>
      </div>
    </div>
  </form>
</div>
