import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss']
})
export class BannerImageComponent implements OnInit {

  @Input() url;
  @Input() darker;

  bannerDarker = false;
  bannerUrl = '';

  constructor() { }

  ngOnInit(): void {
    this.bannerUrl = this.url;
    this.bannerDarker = this.darker;

  }

}
