import { Component, OnInit } from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from "ngx-gallery-9";

@Component({
  selector: 'app-kiallitas',
  templateUrl: './kiallitas.component.html',
  styleUrls: ['./kiallitas.component.scss']
})
export class KiallitasComponent implements OnInit {


  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '1400px',
        height: '800px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
        preview: false
      },
      // max-width
      {
        breakpoint: 1600,
        width: '1200px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 1200,
        width: '900px',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      {
        breakpoint: 991,
        width: '530px',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        thumbnailsColumns: 5,
        preview: false
      },
      // max-width 400
      {
        breakpoint: 530,
        width: '300px',
        height: '300px',
        preview: false,
        thumbnailsColumns: 4,
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/galery/kiallitas/kiallitas1.jpg',
        medium: 'assets/galery/kiallitas/kiallitas1.jpg',
        big: 'assets/galery/kiallitas/kiallitas1.jpg'
      },
      {
        small: 'assets/galery/kiallitas/kiallitas2.jpg',
        medium: 'assets/galery/kiallitas/kiallitas2.jpg',
        big: 'assets/galery/kiallitas/kiallitas2.jpg'
      },
      {
        small: 'assets/galery/kiallitas/kiallitas3.jpg',
        medium: 'assets/galery/kiallitas/kiallitas3.jpg',
        big: 'assets/galery/kiallitas/kiallitas3.jpg'
      },
      {
        small: 'assets/galery/kiallitas/kiallitas4.jpg',
        medium: 'assets/galery/kiallitas/kiallitas4.jpg',
        big: 'assets/galery/kiallitas/kiallitas4.jpg'
      },
      {
        small: 'assets/galery/kiallitas/kiallitas5.jpg',
        medium: 'assets/galery/kiallitas/kiallitas5.jpg',
        big: 'assets/galery/kiallitas/kiallitas5.jpg'
      },
    ];

  }
}
