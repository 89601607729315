<app-menu></app-menu>

<div>
  <app-banner-image [url]="'assets/img/galery/butor5.jpg'" [darker]="false"></app-banner-image>
</div>

<div class="container-80">
  <h4 class="title-contact">Kérjen tőlünk árajánlatot!</h4>

  <div class="container-fluid">
    <form id="contact" (ngSubmit)="onSubmit(contactForm);" #contactForm="ngForm">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <input [(ngModel)]="emailMail.first_name" name="first_name" id="first-name-for" class="form-control"
                   placeholder="Vezetéknév">
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <input [(ngModel)]="emailMail.last_name" name="last_name" id="last-name-for" class="form-control"
                   placeholder="Keresztnév">
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <input [(ngModel)]="emailMail.email" name="email" id="email-for" class="form-control"
                   placeholder="E-mail cím">
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <input [(ngModel)]="emailMail.phone" name="phone" id="phone-for" class="form-control"
                   placeholder="Telefonszám">
          </div>
        </div>

        <div class="col-12 col-lg-12">
          <div class="form-group">
            <input [(ngModel)]="emailMail.package" name="message" id="message-for" class="form-control form-control--message"
                   placeholder="Milyen termékünkről kérne árajánlatot?">
          </div>
        </div>

        <div class="col-12">
          <p style="color: red;">{{ errorCode }}</p>
          <button type="submit" class="btn btn-primary btn-send-email" [disabled]="contactForm.invalid">Küldés</button>
        </div>
      </div>
    </form>
  </div>

</div>

<div class="unique">
  <div class="row row--info">
    <div class="col-12 col-lg-6">
      <p class="unique__title">Vegye fel velünk a kapcsolatot!!</p>
    </div>
    <div class="col-12 col-lg-6 unique__secound-info">
      <a routerLink="/kapcsolat" target="_blank">
        <button class="btn btn-success btn-success--info">Írok nektek</button>
      </a>
    </div>
  </div>
</div>


<app-footer></app-footer>
