
<div class="container-fluid machinery-info__block">

  <h2 class="machinery-info__h2">Géppark</h2>

  <div class="row">
    <div class="col-12 col-lg-6 col-xl-4">
      <div class="machinery-info">

        <div class="machinery-info__image-box">
          <img src="assets/img/banner/tablafel.jpg">
        </div>

        <div class="info-box">
          <h3>Táblafelosztó</h3>
          <p class="info-box__description">Ez fogadja az irodából a tervekből kinyert alkatrész méreteket és annak
            megfelelően vágja fel a 3m x 2m
            laminált forgácslapot a megfelelő méretűre.</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4">
      <div class="machinery-info">
        <div class="machinery-info__image-box">
          <img src="assets/img/banner/elzaro_gep.jpg">
        </div>

        <div class="info-box">
          <h3>Élzáró gép</h3>
          <p class="info-box__description">A méretre vágott alkatrészek élesek és sérülékenyek. Az
            élzárógép hivatott az élvédő anyagot felragasztani az anyag élére. A gép különféle élzáró vastagsága és
            szélességeket is kezel esetenként még a hátfal helyét is elkészíti.</p>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6 col-xl-4 m-auto">
      <div class="machinery-info">
        <div class="machinery-info__image-box">
          <img src="assets/img/banner/cnc_v.jpg">
        </div>

        <div class="info-box">
          <h3>CNC</h3>
          <p class="info-box__description">"A modern kor asztalosa "</p>
          <p class="info-box__description">
            Ezzel a géppel készítjük el az összes szerkezeti furatot és kivágást az alapanyagra. Az előgyártmány már kész
            alkatrészként "lapraszerelt állapotban" jön le a gépről.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
