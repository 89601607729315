import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './page/menu/menu.component';
import { HomeComponent } from './page/home/home.component';
import { MachineComponent } from './page/machine/machine.component';
import { CarouselComponent } from './page/carousel/carousel.component';
import { WorkflowComponent } from './page/workflow/workflow.component';
import { FurniturePanelComponent } from './page/furniture-panel/furniture-panel.component';
import { FooterComponent } from './page/footer/footer.component';
import { ServiceHomeComponent } from './service/service-home/service-home.component';
import {HttpClientModule} from "@angular/common/http";
import {RouterModule, ROUTES} from "@angular/router";
import { BannerImageComponent } from './service/banner-image/banner-image.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { GaleryHomeComponent } from './galery/galery-home/galery-home.component';
import { NgxGalleryModule} from "ngx-gallery-9";
import { EmailHomeComponent } from './email/email-home/email-home.component';
import { ContactHomeComponent } from './contact/contact-home/contact-home.component';
import { BidHomeComponent } from './bid/bid-home/bid-home.component';
import * as nodemailer from 'nodemailer';
import { MetaComponent } from './meta/meta.component';
import { FurniturePanelPageComponent } from './furniture-panel-page/furniture-panel-page.component';
import { GaleryMainComponent } from './page/galery-main/galery-main.component';
import { MuhelyComponent } from './galery/muhely/muhely.component';
import { KiallitasComponent } from './galery/kiallitas/kiallitas.component';
import { LatvanytervekComponent } from './galery/latvanytervek/latvanytervek.component';
import { ElkeszultMunkakComponent } from './galery/elkeszult-munkak/elkeszult-munkak.component';



@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    MachineComponent,
    CarouselComponent,
    WorkflowComponent,
    FurniturePanelComponent,
    FooterComponent,
    ServiceHomeComponent,
    BannerImageComponent,
    GaleryHomeComponent,
    EmailHomeComponent,
    ContactHomeComponent,
    BidHomeComponent,
    MetaComponent,
    FurniturePanelPageComponent,
    GaleryMainComponent,
    MuhelyComponent,
    KiallitasComponent,
    LatvanytervekComponent,
    ElkeszultMunkakComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NgxGalleryModule,
        ReactiveFormsModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
